import React from 'react';
import { FlexColumns, Sidepanel, Typography } from '@alphakits/ui';
import { QuestroomAdminRO } from '@escapenavigator/types/dist/questroom/questroom-admin.ro';

type Props = {
    questroom: QuestroomAdminRO;
};

export const QuestroomModal = ({ questroom }: Props) => (
    <Sidepanel title={ questroom.title }>
        <FlexColumns columns={ 1 } gr={ 42 }>
            <FlexColumns columns={ 1 } gr={ 16 }>
                <Typography.Title view="xsmall" weight="bold" tag="div">
                    Awards
                </Typography.Title>
            </FlexColumns>

            <FlexColumns columns={ 1 } gr={ 0 }>
                { Object.entries(questroom)
                    .sort()
                    .map(([key, value]) => (
                        <Typography.Text view="caps">
                            <b>{ key }</b>: { JSON.stringify(value) }
                        </Typography.Text>
                    )) }
            </FlexColumns>
        </FlexColumns>
    </Sidepanel>
);
